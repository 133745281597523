<template>
    <div>
        <!-- <div><span>*</span>出于对您的公会及账号收益进行安全保护，以及正常财务结算，请完成会长个人信息认证，认证后即可查阅各项数据。</div> -->
        <van-notice-bar left-icon="volume-o" text="出于对您的公会及账号收益进行安全保护，以及正常财务结算，请完成会长个人信息认证，认证后即可查阅各项数据。" />
        <van-form>
            <van-field v-model="guildInfo.id" label="公会ID" readonly />
            <van-field v-model="guildInfo.name" label="公会名称" readonly />
            <van-field v-model="guildInfo.user_id" label="会长ID" readonly />
            <van-field v-model="guildInfo.nick_name" label="会长昵称" readonly />
        </van-form>
        <van-notice-bar :scrollable="false" :text="tip" />
        <van-form @submit="onSubmit">
            <van-field v-model="union_lead_real_name" name="union_lead_real_name" label="真实姓名" placeholder="请如实填写会长真实姓名"
                :rules="[{ required: true, message: '请填写会长真实姓名' }]" />
            <van-field v-model="union_card_no" name="union_card_no" label="身份证号" placeholder="请如实填写会长身份证号"
                :rules="[{ required: true, message: '请填写会长身份证号' }]" />
            <van-field label="身份证照片" />
            <div style="display:flex;justify-content:space-around;background-color: #fff;">
                <van-uploader :before-read="beforeRead1">
                    <div class="upload_btn back"></div>
                </van-uploader>
                <van-uploader :before-read="beforeRead2">
                    <div class="upload_btn front"></div>
                </van-uploader>
            </div>

            <van-field v-model="bank_no" name="bank_no" label="银行卡号" placeholder="为确保收款，请如实填写"
                :rules="[{ required: true, message: '请填写银行卡号' }]" />
            <van-field v-model="bank_name" name="bank_name" label="银行" placeholder="银行名称，例如：工商银行"
                :rules="[{ required: true, message: '请填写银行名称' }]" />
            <van-field v-model="bank_branch" name="bank_branch" label="开户支行" placeholder="开户支行，例如：无锡新吴区支行"
                :rules="[{ required: true, message: '请填写开户支行' }]" />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>

</template>
<script>
import { GuildUserRegist } from "@/api/api";
export default {
    data() {
        return {
            guildInfo: {
                name: '',
                id: '',
                user_id: '',
                nick_name: ''
            },
            tip: '请补充以下资料：',
            union_lead_real_name: '',
            union_card_no: '',
            bank_no: '',
            bank_name: '',
            bank_branch: '',
            card_front: '',
            card_side: '',
            union_id:30
        };
    },
    methods: {
        onSubmit(values) {
            console.log('submit', values);
            values.card_front = this.card_front
            values.card_side = this.card_side
            values.union_id = this.union_id
            console.log(values)
            this.submitForm(values)

        },
        beforeRead1(file) {
            console.log(file)
            this.card_front = file
        },
        beforeRead2(file) {
            console.log(file)
            this.card_side = file
        },
        submitForm(obj) {
            let param = obj
            GuildUserRegist(param).then(res => {
                console.log(res)
            })
        }
    },
};
</script>
<style scoped>
.upload_btn {
    width: 300px;
    height: 200px;
    border: 1px dashed #999999;
    border-radius: 20px;
    background-repeat: no;
    background-size: 100% 100%;
}

.front {
    background-image: url('/img/guildWater/zm.png');
}

.back {
    background-image: url('/img/guildWater/fm.png');
}
</style>